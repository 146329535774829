<template>
	<div>
		<ContentHeader title="Laporan Jasa Medis" subTitle="Rincian Fee Perawat" url="laporan-jasa-medis" />
		<div class="content-header">
			<div class="container-fluid">
				<div class="card">
          <div class="card-header">
            <h3 class="card-title">Data Laporan</h3>
          </div>
          <div class="card-body" style="display: block;">
          	<div class="row" v-if="toogleCabang">
          		<div class="col-lg-4">
          			<label>Pilih Cabang</label>
          			<multiselect 
				          v-model="cabang_select_value"
				          deselect-label="Can't remove this value"
				          track-by="nama"
				          label="nama"
				          placeholder=""
				          :options="cabang"
				          :loading="loading_cabang"
				          :allow-empty="false"
				          @update:model-value="getDataResult"
				        >
				        </multiselect>
          		</div>
          	</div>
            <div class="row align-items-center mt-2">
              <div class="col-lg-2">
      					<span>Periode Awal</span>
      					<input type="date" class="form-control" v-model="periode_awal">
      				</div>
      				<div class="col-lg-2">
      					<span>Periode Akhir</span>
      					<input type="date" class="form-control" v-model="periode_akhir">
      				</div>
      				<div class="col-lg-1 pt-4">
      					<button class="btn btn-primary" @click="getDataResult">Filter</button>
      				</div>
              <div class="col-lg-2"></div>
              <div class="col-lg-5">
              	<span>Perawat</span>
              	<multiselect 
				          v-model="beautician_select_value"
				          deselect-label="Can't remove this value"
				          track-by="nama_lengkap"
				          label="nama_lengkap"
				          placeholder=""
				          :custom-label="customSelectBeautician"
				          :options="beautician"
				          :loading="loading_beautician"
				          :allow-empty="false"
				          @update:model-value="getDataResult"
				        >
				        </multiselect>
              </div>
            </div>
            <table class="table table-bordered table-hover mt-4">
              <thead>
                <tr>
                  <th scope="col" class="text-sm">TANGGAL</th>
                  <th scope="col" class="text-sm">PASIEN</th>
                  <th scope="col" class="text-sm">TINDAKAN</th>
                  <th scope="col" class="text-sm">NILAI(RP)</th>
                  <th scope="col" class="text-sm">DISC</th>
                  <th scope="col" class="text-sm">NILAI - DISC</th>
                  <th scope="col" class="text-sm">FEE TINDAKAN</th>
                  <th scope="col" class="text-sm">TOTAL FEE</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="row in data_result" :key="row.id">
                  <td class="text-sm p-2" scope="row"  :rowspan="row.transaksi_detail.length">
                    <table class="table-borderless">
                      <tr>
                        <td class="text-sm p-2" scope="row" >{{ row.created_at.slice(0, 10) }}</td>
                      </tr>
                    </table>
                  </td>
                  <td class="text-sm p-2" scope="row"  :rowspan="row.transaksi_detail.length">
                    <table class="table-borderless">
                      <tr>
                        <td class="text-sm p-2" scope="row" >{{ row.pasien.nama }}</td>
                      </tr>
                    </table>
                  </td>
                  <td class="text-sm p-2" scope="row" >
                    <table class="table-borderless">
                      <tr v-for="(value, index) in row.transaksi_detail" :key="index">
                        <td class="text-sm p-2" scope="row" >{{ value.nama }}</td>
                      </tr>
                    </table>
                  </td>
                  <td class="text-sm p-2" scope="row" >
                    <table class="table-borderless">
                      <tr v-for="(value, index) in row.transaksi_detail" :key="index">
                        <td class="text-sm p-2" scope="row" >{{ format_nominal(value.harga_awal) }}</td>
                      </tr>
                    </table>
                  </td>
                  <td class="text-sm p-2" scope="row" >
                    <table class="table-borderless">
                      <tr v-for="(value, index) in row.transaksi_detail" :key="index">
                        <td class="text-sm p-2" scope="row" >{{ convertToNominal(value.harga_awal, value.diskon) }}</td>
                      </tr>
                    </table>
                  </td>
                  <td class="text-sm p-2" scope="row" >
                    <table class="table-borderless">
                      <tr v-for="(value, index) in row.transaksi_detail" :key="index">
                        <td class="text-sm p-2" scope="row" >{{ format_nominal(value.harga_akhir) }}</td>
                      </tr>
                    </table>
                  </td>
                  <td class="text-sm p-2" scope="row" >
                    <table class="table-borderless">
                      <tr v-for="(value, index) in row.transaksi_detail" :key="index">
                        <td class="text-sm p-2" scope="row" >{{ format_nominal(value.fee_beautician) }}</td>
                      </tr>
                    </table>
                  </td>
                  <td class="text-sm p-2" scope="row"  :rowspan="row.transaksi_detail.length">
                    <table class="table-borderless">
                      <tr>
                        <td class="text-sm p-2" scope="row" >{{ format_nominal(row.total_fee) }}</td>
                      </tr>
                    </table>
                  </td>
                </tr>
                <tr v-if="total != ''">
                	<td class="font-weight-bold text-center" colspan="3">TOTAL</td>
                	<td class="font-weight-bold text-center">{{ format_nominal(total.harga_awal) }}</td>
                  <td></td>
                  <td class="font-weight-bold text-center">{{ format_nominal(total.harga_akhir) }}</td>
                  <td></td>
                  <td class="font-weight-bold text-center">{{ format_nominal(total.fee) }}</td>
                </tr>
              </tbody>
            </table>
            <p class="text-center mt-2" v-if="memuat_data">Memuat Data...</p>
            <p class="text-center mt-2">{{ cek_data }}</p>
          </div>
          <!-- /.card-body -->
          <div class="card-footer">
          </div>
          <!-- /.card-footer-->
        </div>
			</div>
		</div>
	</div>
</template>

<script setup>
import ContentHeader from '@/components/ContentHeader2'
import Multiselect from '@suadelabs/vue3-multiselect'

import { ref, computed, onMounted } from 'vue'
import store from '@/store'
import axios from 'axios'
import format_nominal from '@/format_nominal'

const user = computed(() => store.getters['auth/user'])
const cabangLogin = computed(() => store.getters['auth/cabang'])
const data_result = ref([])
const cek_data = ref('')
const memuat_data = ref(false)
const periode_awal = ref('')
const periode_akhir = ref('')
const total = ref('')

const getDataResult = async () => {
  data_result.value = []
  total.value = ''
  memuat_data.value = true
  cek_data.value = ''
  let beautician_id = ''
  let tanggal_awal = ''
  let tanggal_akhir = ''
  let cabang_id = ''

  if (cabang_select_value.value == '') {
    cabang_id = user.value.cabang_id
    getBeautician(user.value.cabang_id)
  } else {
    cabang_id = cabang_select_value.value.id
    getBeautician(cabang_select_value.value.id)
  }

  if (periode_awal.value == '' && periode_akhir.value == '') {
    tanggal_awal = 'emptyParameter'
    tanggal_akhir = 'emptyParameter'
  } else {
    tanggal_awal = periode_awal.value
    tanggal_akhir = periode_akhir.value
  }

  if (beautician_select_value.value == '') {
    beautician_id = 'emptyParameter'
  } else {
    beautician_id = beautician_select_value.value.id
  }

  let { data } = await axios.get(`api/laporan/rincianFeeTindakanBeautician/${cabang_id}/${tanggal_awal}/${tanggal_akhir}/${beautician_id}`)

  if (data == 'kosong') {
    cek_data.value = 'Data Masih Kosong'
    memuat_data.value = false
    data_result.value = []
  } else if (data == 'choose_beautician') {
    cek_data.value = 'Pilih Perawat'
    memuat_data.value = false
    data_result.value = []
  } else {
    memuat_data.value = false
    cek_data.value = ''
    data_result.value = data.data
    total.value = data.total
  }
}

const toogleCabang = ref(false)
const cabang_select_value = ref('')
const cabang = ref([])
const loading_cabang = ref(true)
const getCabang = async () => {
  let response = await axios.get(`api/cabang/getWhereId/${user.value.cabang_id}`)

  if (response.data.jenis == 'pusat') {
    toogleCabang.value = true
    let { data } = await axios.get(`api/cabang/getWhereCabang/${response.data.id}`)
    
    if (data != 'kosong') {
      cabang.value = data
      loading_cabang.value = false
    } else {
      cabang.value = []
      loading_cabang.value = false
    }
  }
}

const beautician = ref([])
const beautician_select_value = ref('')
const loading_beautician = ref(true)

const customSelectBeautician = ({ nama_lengkap }) => {
  return `${nama_lengkap}`
}

const getBeautician = async (id) => {
  loading_beautician.value = true
	let jabatan = cabangLogin.value.tipe == 'kecantikan' ? 'beautician' : 'asisten perawat'
  let { data } = await axios.get(`api/karyawan/getAllWhereJabatan/${id}/${jabatan}`)

  if (data != 'kosong') {
    beautician.value = data
    loading_beautician.value = false
  } else {
    beautician.value = []
    loading_beautician.value = false
  }
}

const convertToNominal = (harga_awal, diskon) => {

  if (diskon > 0 && diskon <= 100) {
    return format_nominal((harga_awal * diskon) / 100)
  } else if (diskon == 0) {
    return 0
  } else {
    return format_nominal(diskon)
  }
}


onMounted(() => {
  getCabang()
  getDataResult()
})
</script>